<template>
  <div>
	  <section>
		  	<div class="fixed-button" @click="openPortalAluno">
				<i class="fa fa-user"></i>
				<div>
				Portal do Aluno
				</div>
			</div>
		  <div class="banner">
			  <div class="logo-container">
				  <div class="logotipo"></div>
				  Colégio Técnico São Francisco de Assis</div>
			  <div class="container-banner">
				  <div class="text">Baixe nosso app!
					  <div class="buttons-stores">
						  <a target="_blank" :href="link_ios_store"><div class="phone-ios"></div></a>
					  	  <a target="_blank" :href="link_android_store"><div class="phone-android"></div></a>
					  </div>
				  </div>
				  <div class="header-iphone-container">
				  <div class="header-iphone"></div>
				  </div>
			  </div>
		  </div>
	  </section>
	  
	  <section class="section-gallery">
		  <div class="gallery">
			  <div class="photo photo-1"></div>
			  <div class="photo photo-2"></div>
			  <div class="photo photo-3"></div>
			  <div class="photo photo-4"></div>
		  </div>
	  </section>

	 <div class="title-info">Entre em contato conosco</div>
	  <section class="section-info">
	  	<div class="container-info">
			  <div class="container-ul">
				  <ul>
					 <li><i class="fa fa-phone" aria-hidden="true"></i><a :href="`tel://${phone}`">{{ phone }}</a></li>
					 <li><i class="fa fa-map-marker" aria-hidden="true"></i>{{ endereco }}</li>
					 <li><i class="fa fa-envelope" aria-hidden="true"></i><a :href="`mailto:${email}`">{{ email }}</a></li>
					 <li><i class="fa fa-clock-o" aria-hidden="true"></i> {{ horario_funcionamento }}</li>
					 <li>
						 <!-- <a :href="link_facebook" target="_blank" class="margin-5"><i class=" fa fa-whatsapp" aria-hidden="true"></i></a> -->
						 <a :href="link_facebook" target="_blank" class="margin-5"><i class=" fa fa-facebook" aria-hidden="true"></i></a>
						 <a :href="link_instagram" target="_blank" class="margin-5"><i class=" fa fa-instagram" aria-hidden="true"></i></a>
					 </li>
				  </ul>
			  </div>
	  	</div>
	  	<div class="container-map">
			  <div class="square-back-map"></div>
			  <div class="map">
				  <!-- map -->				  
				  <iframe :src="link_maps"  frameborder="0" style="border:0;width: 100%;height: 100%;" allowfullscreen=""></iframe>
				  <!-- map -->
			  </div>
	  	</div>
	  </section>
	
	  <section class="section-footer">
	  	<div class="footer">
			  <a href="https://jrsistemas.net" target="_blank" class="link-footer"> Copyright © 2003-{{new Date().getFullYear()}} JR SISTEMAS E TECNOLOGIA LTDA
			05.964.446/0001-50. Todos direitos reservados.</a>
	  	</div>
	  </section>
 
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
	name: 'Home',
  components: {
	  HelloWorld
  },
  data(){
	  return{
		  link_ios_store: "",
		  link_android_store: "",
		  link_facebook: "",
		  link_whatsapp: "",
		  link_instagram: "",
		  link_portal_do_aluno: "",
		  phone:"",
		  endereco:"",
		  email:"",
		  horario_funcionamento:"",
		  link_maps:""
	  }
  },
 created(){
	 axios.get('config/config.json').then(res=>{
		 let { link_ios_store,
		  	   link_android_store,
		  	   link_facebook,
		  	   link_whatsapp,
		  	   link_instagram,
			   link_portal_do_aluno,
			   phone,
			   endereco,
			   email,
			   horario_funcionamento,
			   link_maps
			} = res.data;

		  this.link_ios_store = link_ios_store;		
		  this.link_android_store = link_android_store;
		  this.link_facebook = link_facebook;
		  this.link_whatsapp = link_whatsapp;
		  this.link_instagram = link_instagram;
		  this.link_portal_do_aluno = link_portal_do_aluno;
		  this.phone = phone;
		  this.endereco = endereco;
		  this.email = email;
		  this.horario_funcionamento = horario_funcionamento;
		  this.link_maps = link_maps;
	 })
 },
 methods:{
	openPortalAluno(){
		window.open(this.link_portal_do_aluno, "_blank")
	}
 }
}
</script>
